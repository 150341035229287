import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  setUsers,
  setSelectedUser,
  setOpenUserDialogForm as setOpenDialog,
  setUserDeleteSuccess,
  setIsLoading,
  setRoles,
} from "../../../State/actions";
import EditIcon from "../../../Components/Icons/EditIcon.svg";
import DeleteIcon from "../../../Components/Icons/DeleteIcon.svg";
import { toast } from "react-toastify";

import {
  GridActionsCellItem,
  // GridRowEditStopReasons,
} from "@mui/x-data-grid";

import "./UserManagementPage.css";

import DataTable from "../../../Components/DataGrid/DataGrid";
import AxiosWrapper from "../../../Api/http/AxiosWrapper";
const { REACT_APP_API_BASE_URL } = process.env;

import getUsersData from "../../../Api/request/users";
import getRolesData from "../../../Api/request/roles";

// import { create } from "@mui/material/styles/createTransitions";

const usersApi = new AxiosWrapper(REACT_APP_API_BASE_URL);
const rolesAPI = new AxiosWrapper(REACT_APP_API_BASE_URL);

const handleRowEdit = (id, row) => () => {
  console.log("handleRowEdit :" + id);
  setSelectedUser(row);
  setOpenDialog(true);
};

const handleRowDelete = (id) => async () => {
  console.log("handleRowDelete:" + id);
  await deleteUser(id);
};

const handleFormSubmit = (selectedUser, usersState, setUsersState) => () => {
  if (!selectedUser.id) {
    createUser(selectedUser, usersState, setUsersState);
  } else {
    // Edit User
    updateUser(selectedUser, usersState, setUsersState);
  }
};

const columnsArr = [
  // { field: "id", headerName: "ID", width: 150 },
  { field: "name", headerName: "Name", width: 150 },
  { field: "email", headerName: "Email", width: 200 },
  { field: "title", headerName: "Title", width: 100 },
  {
    field: "status",
    headerName: "Status",
  },
  {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    width: 200,
    cellClassName: "actions",
    getActions: ({ id, row }) => {
      return [
        <GridActionsCellItem
          icon={<img src={EditIcon} alt="Edit Icon" />}
          label="Edit"
          className="textPrimary"
          onClick={handleRowEdit(id, row)}
          color="inherit"
          key="edit"
        />,
        <GridActionsCellItem
          icon={<img src={DeleteIcon} alt="Edit Icon" />}
          label="Delete"
          onClick={handleRowDelete(id)}
          color="inherit"
          key="delete"
        />,
      ];
    },
  },
];

export const fetchUsers = async (page, pageSize, setUsersState, setPaginationModel) => {
  try {
    setIsLoading(true);
    const usersRequestData = getUsersData();
    const response = await usersApi.get(
      `${usersRequestData.url}?page=${page}&pageSize=${pageSize}`
    );

    // Safe null checks
    if (!response || !response.data) {
      throw new Error("Invalid response from server");
    }

    const responseData = response.data;

    if (!responseData.data || !responseData.data.users) {
      throw new Error("Invalid data structure in response");
    }

    const { users = [], pagination = {} } = responseData.data;
    setUsersState(users);
    if (setPaginationModel) {
      setPaginationModel((prev) => ({
        ...prev,
        ...pagination
      }));
    }

    setIsLoading(false);
  } catch (error) {
    console.error(error?.message);
    toast.error(error?.response?.data?.message || error?.message);
    setIsLoading(false);
  }
};

export const fetchRoles = async (setRolesState) => {
  try {
    setIsLoading(true);
    const rolesRequestData = getRolesData();
    const response = await rolesAPI.get(rolesRequestData.url);
    const roles = response?.data?.data.roles;
    // Transform roles data to match the required structure
    const formattedRoles = roles.map((role) => ({
      value: role.id,
      label: role.name,
    }));

    // Update the dialog form inputs with the formatted roles
    dialogFormInputs.find((input) => input.id === "roles").options = formattedRoles;
    setRolesState(roles);
    // toast.success(response?.data?.message || "Roles fetched successfully");
    setIsLoading(false);
    // return users;
  } catch (error) {
    console.error(error?.message);

    toast.error(error?.response?.date?.data?.message || error?.message);
    setIsLoading(false);
  }
};

const createUser = async (selectedUser, usersState, setUsersState) => {
  try {
    setIsLoading(true);
    const usersRequestData = getUsersData();
    const response = await usersApi.post(usersRequestData.url, selectedUser);
    const user = response?.data?.data?.user;
    setUsersState([...usersState, user]);
    toast.success(response?.data?.data?.message || "User Created Successfully");
    setIsLoading(false);
  } catch (error) {
    console.error(error?.message);

    toast.error(error?.response?.date?.data?.message || error?.message);
    setIsLoading(false);
  }
};

const updateUser = async (selectedUser, usersState, setUsersState) => {
  try {
    setIsLoading(true);
    const usersRequestData = getUsersData();
    const id = selectedUser?.id;
    const response = await usersApi.put(
      `${usersRequestData.url}/${id}`,
      selectedUser
    );
    const user = response?.data?.data?.user;
    const newState = usersState?.map((userState) => {
      if (userState.id === selectedUser.id) return user;
      else return userState;
    });
    setUsersState(newState);
    toast.success(response?.data?.data?.message || "User updated successfully");
    setIsLoading(false);
  } catch (error) {
    console.error(error?.message);

    toast.error(error?.response?.date?.data?.message || error?.message);
    setIsLoading(false);
  }
};

const deleteUser = async (id) => {
  try {
    const isconfirmed = confirm(
      "Are you sure you want to delete the user with id: " + id
    );
    if (!isconfirmed) return;
    setIsLoading(true);
    const usersRequestData = getUsersData();
    await usersApi.delete(`${usersRequestData.url}/${id}`);

    setUserDeleteSuccess(id);
    toast.success("User deleted successfully");
    setIsLoading(false);
  } catch (error) {
    console.error(error?.message);

    toast.error(error?.response?.date?.data?.message || error?.message);
    setIsLoading(false);
  }
};

const dialogFormInputs = [
  {
    id: "name",
    label: "Name",
    type: "text",
    required: true,
    disabled: false,
  },
  {
    id: "email",
    label: "Email",
    type: "text",
    disabled: false,
    required: true,
  },
  {
    id: "title",
    label: "Title",
    type: "select",
    selectedValue: "LEAD",
    options: [
      { value: "LEAD", label: "Lead" },
      { value: "ARCHITECT", label: "Architect" },
    ],
  },
  {
    id: "status",
    label: "Status",
    type: "select",
    selectedValue: "ACTIVE",
    options: [
      { value: "ACTIVE", label: "Active" },
      { value: "INACTIVE", label: "In active" },
      { value: "DISABLED", label: "disabled" },
    ],
  },
  {
    id: "roles",
    label: "Role",
    type: "select",
    selectedValue: "",
    isMultiple: true,
    options: [],
    validation: {
      min: 1, // Minimum 1 selection
      max: 10, // Maximum 3 selections
      errorMessage: "Please select between 1 and 10 roles.",
    },
  },
];

const userManagement = () => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState(columnsArr);
  const [searchValue, setSearchValue] = useState("");

  const usersState = useSelector((state) => state?.user?.users);
  const openDialog = useSelector((state) => state?.user?.openDialog);
  const selectedUser = useSelector((state) => state.user.selectedUser);
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 5,
    totalItems: 0,
  });

  // const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    fetchUsers(
      paginationModel.page,
      paginationModel.pageSize,
      setUsers,
      setPaginationModel
    );
    fetchRoles(setRoles);
  }, [paginationModel.page, paginationModel.pageSize]);

  useEffect(() => {
    setRows(usersState);
  }, [usersState]);

  const LOCKED_FIELDS = ["name", "email"];
  const disabledDialogFormInputs = dialogFormInputs?.map((input) => ({
    ...input,
    disabled: LOCKED_FIELDS.includes(input?.id),
  }));

  const handlePaginationChange = (data) =>
    setPaginationModel({ page: data?.page + 1, pageSize: data?.pageSize });

  return (
    <div className="user-manstatusment-container">
      <DataTable
        rows={rows}
        columns={columns}
        setColumns={setColumns}
        paginationModel={paginationModel}
        pageSizeOptions={[5, 10, 15]}
        opendialog={openDialog}
        setOpenDialog={setOpenDialog}
        formInputs={
          selectedUser?.id ? disabledDialogFormInputs : dialogFormInputs
        }
        formState={selectedUser}
        setFormState={setSelectedUser}
        handleFormSubmit={handleFormSubmit(selectedUser, usersState, setUsers)}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        enableAddRecord={false}
        addDialogTitle={"Add User"}
        editDialogTitle={"Edit User"}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  );
};

export default userManagement;
