import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./FormDialog.css";
import { Checkbox, ListItemText } from "@mui/material";
import { Tooltip } from "@mui/joy";

const handleClose = (event, setOpen, resetFormState) => {
  setOpen(false);
  resetFormState({});
};

const handleSelectChange = (
  event,
  id,
  formState,
  setSelectState,
  isMultiple
) => {
  console.log(event);
  if (!isMultiple) {
    setSelectState({ ...formState, [id]: event.target.value });
  } else {
    setSelectState({
      ...formState,
      [id]:
        typeof event.target.value === "string"
          ? event.target.value.split(",")
          : event.target.value,
    });
  }
};

const handleTextChange = (
  event,
  id,
  formState,
  setTextState,
  input,
  setErrors
) => {
  const value = event.target.value;
  setTextState({ ...formState, [id]: value });

  // Validate on change
  if (input.validation && input.validation.pattern) {
    const isValid = input.validation.pattern.test(value);
    setErrors((prev) => ({
      ...prev,
      [id]: isValid ? "" : input.validation.errorMessage,
    }));
  }
};
/* eslint-disable max-lines-per-function */

const FormInput = ({ input, setFormState, formState, errors, setErrors }) => {
  if (input.type === "text") {
    return (
      <TextField
        required={input.required}
        disabled={input.disabled}
        margin="dense"
        id={input.name}
        name={input.name}
        label={input.label}
        type={input.type}
        fullWidth
        variant="outlined"
        key={input.name}
        onChange={(event) =>
          handleTextChange(
            event,
            input.id,
            formState,
            setFormState,
            input,
            setErrors
          )
        }
        value={formState[input.id] || ""}
        error={Boolean(errors[input.id])} // Sets the error border
        helperText={errors[input.id] || ""} // Displays the error message only if there's an error
        FormHelperTextProps={{
          style: { color: "red" }, // Red color for the error message
        }}
      />
    );
  } else if (input.type === "select") {
    const getSelectedDescription = () => {
      if (input.isMultiple && Array.isArray(formState[input.id])) {
        const selectedOptions = input.options.filter((opt) =>
          formState[input.id].includes(opt.value)
        );

        return selectedOptions.map((opt) => opt.description).join("\n");
      } else {
        const selectedOption = input.options.find(
          (opt) => opt.value === formState[input.id]
        );

        return selectedOption?.description || "";
      }
    };

    return (
      <FormControl fullWidth error={Boolean(errors[input.id])}>
        <Tooltip title={getSelectedDescription()} placement="top-start">
          <InputLabel id={input.label}>{input.label}</InputLabel>
        </Tooltip>
        <Select
          labelId={input.label}
          id={input.id}
          value={formState[input.id] || (input.isMultiple ? [] : "")}
          multiple={input.isMultiple}
          label={input.label}
          onChange={(event) =>
            handleSelectChange(
              event,
              input.id,
              formState,
              setFormState,
              input.isMultiple
            )
          }
          renderValue={(selected) => {
            if (Array.isArray(selected)) {
              return selected
                .map(
                  (value) =>
                    input.options.find((option) => option.value === value)
                      ?.label
                )
                .join(", ");
            }

            return input.options.find((option) => option.value === selected)
              ?.label;
          }}
        >
          {input?.options?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {input.isMultiple && (
                <Checkbox
                  checked={
                    Array.isArray(formState[input.id]) && formState[input.id]?.includes(option.value)
                  }
                />
              )}
              <Tooltip title={option.description || ""}>
                <ListItemText primary={option.label} />
              </Tooltip>
            </MenuItem>
          ))}
        </Select>
        {errors[input.id] && (
          <p style={{ color: "red", fontSize: "0.875rem", margin: "8px 0 0" }}>
            {errors[input.id]}
          </p>
        )}
      </FormControl>
    );
  }

  return null;
};

FormInput.propTypes = {
  input: PropTypes.object,
  formState: PropTypes.object,
  setFormState: PropTypes.func,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
};

export default function FormDialog({
  open,
  setOpen,
  formInputs,
  contentText,
  title,
  formState,
  setFormState,
  handleFormSubmit,
}) {
  const [errors, setErrors] = React.useState({});

  const isFormValid = () => {
    let valid = true;
    const newErrors = {};

    formInputs.forEach((input) => {
      const value = formState[input.id] || (input.isMultiple ? [] : "");

      if (input.validation) {
        if (input.isMultiple) {
          const { min, max, errorMessage } = input.validation;
          if (value.length < min || value.length > max) {
            valid = false;
            newErrors[input.id] = errorMessage;
          }
        } else if (input.validation.pattern) {
          const isValid = input.validation.pattern.test(value);
          if (!isValid) {
            valid = false;
            newErrors[input.id] = input.validation.errorMessage;
          }
        }
      }
    });

    setErrors(newErrors);

    return valid;
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (isFormValid()) {
      handleFormSubmit();
      handleClose(null, setOpen, setFormState);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(event) => handleClose(event, setOpen, setFormState)}
      PaperProps={{
        component: "form",
        onSubmit: onSubmit,
        className: "dialog",
      }}
    >
      <DialogTitle className="dialog-title">{title}</DialogTitle>
      <DialogContent className="dialog-content">
        {contentText && (
          <DialogContentText className="dialog-content-text">
            {contentText}
          </DialogContentText>
        )}
        {formInputs?.map((input) => (
          <FormInput
            input={input}
            key={input.id}
            formState={formState}
            setFormState={setFormState}
            errors={errors}
            setErrors={setErrors}
          />
        ))}
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button
          onClick={(event) => handleClose(event, setOpen, setFormState)}
          className="dialog-button-cancel"
        >
          Cancel
        </Button>
        <Button type="submit" className="dialog-button-submit">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FormDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  formInputs: PropTypes.array,
  title: PropTypes.string,
  contentText: PropTypes.string,
  formState: PropTypes.object,
  setFormState: PropTypes.func,
  handleFormSubmit: PropTypes.func,
};
