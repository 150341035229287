import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  setSelectedUser,
  setOpenUserDialogForm as setOpenDialog,
  setIsLoading,
  setPipelineTriggerRequest,
} from "../../../State/actions";

import { toast } from "react-toastify";
import DataTable from "../../../Components/DataGrid/DataGrid";
import AxiosWrapper from "../../../Api/http/AxiosWrapper";
const { REACT_APP_API_BASE_URL } = process.env;

import getTriggerRequestData from "../../../Api/request/triggerRequest";

const trigerRequestApi = new AxiosWrapper(REACT_APP_API_BASE_URL);

const columnsArr = [
  // { field: "id", headerName: "ID", width: 60 },
  { field: "triggerdBy", headerName: "Triggered By", width: 150 },
  { field: "status", headerName: "Status", width: 500 },
  { field: "pipelineId", headerName: "Pipeline/Project" },
  { field: "statusUpdatedBy", headerName: "Approver" },
];

export const fetchTriggerRequest = async (
  page,
  pageSize,
  setTriggerRequestState,
  setPaginationModel
) => {
  try {
    setIsLoading(true);
    const triggerRequestData = getTriggerRequestData();
    const response = await trigerRequestApi.get(
      `${triggerRequestData.url}?page=${page}&pageSize=${pageSize}`
    );

    if (!response?.data?.data) {
      throw new Error("Invalid response from server");
    }

    const { pipelines = [], pagination = {} } = response.data.data;

    setTriggerRequestState(pipelines);
    setPaginationModel((prev) => ({
      ...prev,
      ...pagination,
    }));
  } catch (error) {
    console.error(error?.message);
    toast.error(error?.response?.data?.message || error?.message);
  } finally {
    setIsLoading(false);
  }
};

// const paginationModel = { pstatus: 0, pstatusSize: 50 };

const TriggerRequestTable = () => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState(columnsArr);
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 5,
    totalItems: 0,
  });
  const rowsState = useSelector((state) => state?.pipeline?.triggerRequest);
  const openDialog = useSelector((state) => state?.roles?.openDialog);
  const selectedUser = useSelector((state) => state.user.selectedUser);

  // const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    fetchTriggerRequest(paginationModel.page, paginationModel.pageSize, setPipelineTriggerRequest, setPaginationModel);
  }, [paginationModel.page, paginationModel.pageSize]);

  useEffect(() => {
    setRows(rowsState);
  }, [rowsState]);

  const handlePaginationChange = (data) =>
    setPaginationModel({ page: data?.page + 1, pageSize: data?.pageSize });

  return (
    <div className="user-manstatusment-container">
      <DataTable
        rows={rows}
        columns={columns}
        setColumns={setColumns}
        paginationModel={paginationModel}
        pageSizeOptions={[5, 10, 15, 20]}
        opendialog={openDialog}
        setOpenDialog={setOpenDialog}
        formState={selectedUser}
        setFormState={setSelectedUser}
        enableAddRecord={false}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  );
};

export default TriggerRequestTable;
