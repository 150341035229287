import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const TreeLineHorizontal = styled(Box)(() => ({
  position: "absolute",
  left: 2,
  top: "50%",
  width: "20px",
  height: "0px",
  backgroundColor: "white",
  "&::before": {
    content: '""',
    position: "absolute",
    left: "-7px",
    top: "-28px",
    width: "46px",
    height: "14px",
    border: "3px solid white",
    borderTop: "none",
    borderLeft: "none",
    borderRadius: "0 0 18px 0px",
    transform: "rotate(90deg)",
  },
}));

const StyledNavLink = styled(NavLink)(() => ({
  textDecoration: "none",
  color: "#B0BEC5",
  padding: "4px 0",
  display: "flex",
  alignItems: "center",
  transition: "color 0.2s",
  fontSize: "0.875rem",
  "&:hover": {
    color: "#0CE865",
  },
  "&.active": {
    color: "#0CE865",
  },
}));

const TreeMenuLink = ({ text, path }) => {
  const location = useLocation();
  const isActive = location.pathname === path;

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        mb: 0.5,
      }}
    >
      <TreeLineHorizontal />
      <StyledNavLink
        to={path}
        className={isActive ? "active" : ""}
        sx={{ pl: "32px" }}
      >
        {text}
      </StyledNavLink>
    </Box>
  );
};

TreeMenuLink.propTypes = {
  text: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

const TreeMenu = ({ subItems }) => {
  return (
    <Box sx={{ position: "relative", ml: 3, mt: 2 }}>
      {subItems.map((subItem) => (
        <TreeMenuLink
          key={subItem.text}
          text={subItem.text}
          path={subItem.path}
        />
      ))}
    </Box>
  );
};

TreeMenu.propTypes = {
  subItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default TreeMenu;
