import { PERMISSIONS_API } from "../resources/endPoint";

export const getPermissionsData = () => {
  return {
    url: PERMISSIONS_API,

    method: "get",
    //   tag: ApiTag.CSRF_TOKEN,
    headers: { "content-type": "application/json" },
    authRequired: false,
    csrfToken: true,
  };
};

export default getPermissionsData;
