import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  setOpenRolesDialogForm as setOpenDialog,
  setIsLoading,
  setRoles,
  setSelectedRole,
} from "../../../State/actions";

import { toast } from "react-toastify";
import DataTable from "../../../Components/DataGrid/DataGrid";
import AxiosWrapper from "../../../Api/http/AxiosWrapper";
const { REACT_APP_API_BASE_URL } = process.env;
import EditIcon from "../../../Components/Icons/EditIcon.svg";
import DeleteIcon from "../../../Components/Icons/DeleteIcon.svg";
import getRolesData from "../../../Api/request/roles";
import { GridActionsCellItem } from "@mui/x-data-grid";
import getPermissionsData from "../../../Api/request/permissions";

const rolesAPI = new AxiosWrapper(REACT_APP_API_BASE_URL);

const handleFormSubmit = async (selectedRole) => {
  if (!selectedRole.id) {
    await createRole(selectedRole);
  } else {
    await updateRole(selectedRole);
  }
  // Fetch updated roles after create/update
  await fetchRoles(setRoles);
};

const columnsArr = () => [
  { field: "name", headerName: "Name", width: 150 },
  { field: "description", headerName: "Description", width: 500 },
  { field: "status", headerName: "Status" },
  {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    width: 200,
    cellClassName: "actions",
    getActions: ({ id, row }) => {
      return [
        <GridActionsCellItem
          icon={<img src={EditIcon} alt="Edit Icon" />}
          label="Edit"
          className="textPrimary"
          onClick={handleRowEdit(id, row)}
          color="inherit"
          key="edit"
        />,
        <GridActionsCellItem
          icon={<img src={DeleteIcon} alt="Edit Icon" />}
          label="Delete"
          onClick={handleRowDelete(id)}
          color="inherit"
          key="delete"
        />,
      ];
    },
  },
];

const handleRowEdit = (id, row) => () => {
  setSelectedRole(row);
  setOpenDialog(true);
};

const handleRowDelete = (id) => async () => {
  await deleteRole(id);
};

const dialogFormInputs = [
  {
    id: "name",
    label: "Name",
    type: "text",
    required: true,
  },
  {
    id: "description",
    label: "Description",
    type: "text",
    required: true,
    disabled: false,
  },
  {
    id: "status",
    label: "Status",
    type: "select",
    selectedValue: "ACTIVE",
    options: [
      { value: "ACTIVE", label: "Active" },
      { value: "INACTIVE", label: "In active" },
      { value: "DISABLED", label: "disabled" },
    ],
  },
  {
    id: "permissions",
    label: "Permissions",
    type: "select",
    selectedValue: "",
    options: [],
    required: true,
    disabled: false,
    isMultiple: true,
  },
];

export const fetchPermissions = async () => {
  try {
    setIsLoading(true);
    const permissionsRequestData = getPermissionsData();
    const response = await rolesAPI.get(permissionsRequestData.url);
    const permissions = response?.data?.data?.permissions;

    const formattedPermissions = permissions.map((permission) => ({
      value: permission.id,
      label: permission.name,
      description: permission.description,
    }));

    const permissionsInput = dialogFormInputs.find(
      (input) => input.id === "permissions"
    );
    if (permissionsInput) {
      permissionsInput.options = formattedPermissions;
    }

    setIsLoading(false);
  } catch (error) {
    console.error(error?.message);
    toast.error(error?.response?.data?.message || error?.message);
    setIsLoading(false);
  }
};

export const fetchRoles = async (
  page,
  pageSize,
  setRolesState,
  setPaginationModel
) => {
  try {
    setIsLoading(true);
    const rolesRequestData = getRolesData();
    const response = await rolesAPI.get(
      `${rolesRequestData.url}?page=${page}&pageSize=${pageSize}`
    );

    if (!response?.data?.data) {
      throw new Error("Invalid response from server");
    }

    const { roles = [], pagination = {} } = response.data.data;

    setRolesState(roles);
    setPaginationModel((prev) => ({
      ...prev,
      ...pagination,
    }));
  } catch (error) {
    console.error(error?.message);
    toast.error(error?.response?.data?.message || error?.message);
  } finally {
    setIsLoading(false);
  }
};

export const createRole = async (selectedRole) => {
  try {
    setIsLoading(true);
    const rolesRequestData = getRolesData();
    const response = await rolesAPI.post(rolesRequestData.url, selectedRole);
    toast.success(response?.data?.data?.message || "Role Created Successfully");
    setOpenDialog(false);
    setIsLoading(false);
  } catch (error) {
    console.error(error?.message);
    toast.error(error?.response?.data?.message || error?.message);
    setIsLoading(false);
  }
};

export const updateRole = async (selectedRole) => {
  try {
    setIsLoading(true);
    const rolesRequestData = getRolesData();
    const id = selectedRole?.id;
    const response = await rolesAPI.put(
      `${rolesRequestData.url}/${id}`,
      selectedRole
    );
    toast.success(response?.data?.data?.message || "Role updated successfully");
    setOpenDialog(false);
    setIsLoading(false);
  } catch (error) {
    console.error(error?.message);
    toast.error(error?.response?.data?.message || error?.message);
    setIsLoading(false);
  }
};

export const deleteRole = async (id) => {
  try {
    const isConfirmed = confirm(
      "Are you sure you want to delete the role with id: " + id
    );
    if (!isConfirmed) return;

    setIsLoading(true);
    const rolesRequestData = getRolesData();
    await rolesAPI.delete(`${rolesRequestData.url}/${id}`);
    toast.success("Role deleted successfully");
    setIsLoading(false);
    // Fetch updated roles after deletion
    await fetchRoles(setRoles);
  } catch (error) {
    console.error(error?.message);
    toast.error(error?.response?.data?.message || error?.message);
    setIsLoading(false);
  }
};

// const paginationModel = { pstatus: 0, pstatusSize: 50 };

const RolesTable = () => {
  const [rows, setRows] = useState([]);
  const rowsState = useSelector((state) => state?.roles?.roles);
  const openDialog = useSelector((state) => state?.roles?.openDialog);
  const selectedRole = useSelector((state) => state.roles?.selectedRole);
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 5,
    totalItems: 0,
  });
  const [searchValue, setSearchValue] = useState("");
  const [columns, setColumns] = useState(columnsArr());

  useEffect(() => {
    fetchRoles(
      paginationModel.page,
      paginationModel.pageSize,
      setRoles,
      setPaginationModel
    );
    fetchPermissions();
  }, [paginationModel.page, paginationModel.pageSize]);

  useEffect(() => {
    setRows(rowsState);
  }, [rowsState]);

  const handlePaginationChange = (data) =>
    setPaginationModel({ page: data?.page + 1, pageSize: data?.pageSize });

  return (
    <div className="user-manstatusment-container">
      <DataTable
        rows={rows}
        columns={columns}
        setColumns={setColumns}
        paginationModel={paginationModel}
        pageSizeOptions={[2, 5, 10, 15]}
        opendialog={openDialog}
        setOpenDialog={setOpenDialog}
        formInputs={dialogFormInputs}
        formState={selectedRole}
        setFormState={setSelectedRole}
        handleFormSubmit={() => handleFormSubmit(selectedRole, setRoles)}
        enableAddRecord={true}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        addDialogTitle={"Add Role"}
        editDialogTitle={"Edit Role"}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  );
};

export default RolesTable;
