import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import PropTypes from "prop-types";
// import NivLogo from "./niveus-logo.png";
import DIcon from "./icon.svg";
import LogOutIcon from "./logoutIcon.svg";
import SettingIcon from "./settings.svg";
import DefaultUserIcon from "./defaultUserIcon.svg";
import { useAuth } from "../../Hooks/useAuth";
import { useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TreeMenu from "./TreeMenu";

const drawerWidth = 295;

// Function to map URL paths to titles
const getTitleFromPath = (pathname) => {
  const routes = [
    { path: "/admin-panel/user-management/users", title: "User" },
    { path: "/admin-panel/user-management/roles", title: "Roles" },
    { path: "/admin-panel/pipeline-management/pipelines", title: "Pipeline" },
    {
      path: "/admin-panel/pipeline-management/trigger-request",
      title: "Pipeline Trigger Request",
    },
    { path: "/admin-panel/project-management", title: "Project Management" },
    { path: "/admin-panel/settings", title: "Settings" },
  ];

  // Find the route with the longest matching prefix
  const matchedRoute = routes.find((route) => pathname.startsWith(route.path));

  return matchedRoute ? matchedRoute.title : "";
};

/* eslint-disable max-lines-per-function */
const AppBarComponent = () => {
  const location = useLocation();
  const pageTitle = getTitleFromPath(location.pathname); // Get title from URL

  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${drawerWidth}px)`,
        height: "75px",
        ml: `${drawerWidth}px`,
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        color: "#000",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
      }}
    >
      <Toolbar
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Page Title */}
        <Box
          sx={{
            fontSize: "1rem",
            fontWeight: 600,
            color: "#333",
          }}
        >
          {pageTitle}
        </Box>

        {/* Powered by Section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Box
            sx={{
              fontSize: "0.7rem",
              fontStyle: "italic",
              color: "#333",
            }}
          >
            Powered by
          </Box>
          <img
            src={
              "https://niveussolutions.com/wp-content/uploads/2025/02/Niveus-ntt-data.png"
            }
            alt="niv logo"
            height="30px"
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const DrawerComponent = ({ menuItems }) => {
  const { handleLogout } = useAuth();
  const navigate = useNavigate();
  const loggedInUser = useSelector((state) => state.auth?.loggedInUser);

  const handleSignOut = async () => {
    try {
      await handleLogout();
      navigate("/signin");
    } catch (error) {
      console.error("Logout failed:", error);
      alert("Logout failed. Please try again.");
    }
  };

  return (
    <Drawer
      sx={{
        height: "100vh",
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "#2A3749",
          color: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      {/* Header Section */}
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
          padding: "16px",
        }}
      >
        <img
          src={DIcon}
          alt="Dicon"
          style={{ width: "30px", height: "30px" }}
        />
        <Box
          sx={{
            fontSize: "1.7rem",
            fontWeight: "600",
          }}
        >
          DManager
        </Box>
      </Toolbar>
      <Divider sx={{ backgroundColor: "#E4E4E4", margin: "10px 20px" }} />

      {/* Menu Items */}
      <List sx={{ flexGrow: 1, padding: "8px 16px" }}>
        {menuItems.map(({ text, icon, subItems }) => (
          <React.Fragment key={text}>
            <Accordion
              disableGutters
              sx={{
                backgroundColor: "transparent",
                color: "#FFFFFF",
                boxShadow: "none",
                "&::before": { display: "none" },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#FFFFFF" }} />}
                sx={{
                  padding: "0 16px",
                  display: "flex",
                  alignItems: "center",
                  color: "#FFFFFF",
                  fontWeight: 500,
                }}
              >
                {icon && (
                  <Box
                    sx={{
                      marginRight: "12px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {icon}
                  </Box>
                )}
                {text}
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "0 16px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  overflow: "hidden",
                }}
              >
                <TreeMenu subItems={subItems} />
              </AccordionDetails>
            </Accordion>
          </React.Fragment>
        ))}
      </List>

      {/* Footer Section */}
      <Box
        sx={{
          padding: "16px",
          background: "#FFFFFF1A",
          margin: "20px",
          borderRadius: "12px",
        }}
      >
        {/* Profile Section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            borderRadius: "12px",
            backgroundColor: "#2A3749",
            padding: "10px",
          }}
        >
          <img
            src={loggedInUser?.picture || DefaultUserIcon}
            alt="Profile"
            style={{
              width: "60px",
              height: "60px",
              borderRadius: "12px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              fontSize: "0.9rem",
            }}
          >
            <span style={{ fontWeight: "600" }}>{loggedInUser?.name}</span>
          </Box>
        </Box>

        {/* Settings */}
        <ListItem
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            marginBottom: "8px",
          }}
          onClick={() => navigate("/admin-panel/settings")}
        >
          <img src={SettingIcon} alt="Language Icon" />
          <span style={{ color: "ffff" }}>Settings</span>
        </ListItem>
        <Divider sx={{ backgroundColor: "#2A3749" }} />
        {/* Logout */}
        <ListItem
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}
          onClick={handleSignOut}
        >
          <img src={LogOutIcon} alt="Logout Icon" />
          <span style={{ color: "#ffff" }}>Logout</span>
        </ListItem>
      </Box>
    </Drawer>
  );
};

DrawerComponent.propTypes = {
  menuItems: PropTypes.array.isRequired,
};

export default function PermanentDrawerLeft({ menuItems, children }) {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBarComponent />
      <DrawerComponent menuItems={menuItems} />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "#F4F6F8",
          padding: "24px",
          mt: "75px",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

PermanentDrawerLeft.propTypes = {
  menuItems: PropTypes.array.isRequired,
  children: PropTypes.node,
};
