import store from "./store";

const action = store.dispatch;

// loading

export const SET_IS_LOADING = "SET_IS_LOADING";
export const setIsLoading = (payload) => {
  action({ type: SET_IS_LOADING, payload });
};

// auth
export const SET_AUTH_USER = "SET_AUTH_USER";
export const SET_AUTH_SUCCESS_REDIRECT = "SET_AUTH_SUCCESS_REDIRECT";

export const setAuthUser = (payload) => {
  action({ type: SET_AUTH_USER, payload });
};

export const setAuthSuccessRedirect = (payload) => {
  localStorage.setItem("auth_success_redirect", payload);
  action({ type: SET_AUTH_SUCCESS_REDIRECT, payload });
};

// users
export const SET_USERS = "SET_USERS";
export const SET_SELECTED_USER = "SET_SELECTED_USER";
export const SET_OPEN_USER_DIALOG_FORM = "SET_OPEN_USER_DIALOG_FORM";
export const SET_USER_DELETE_SUCCESS = "SET_USER_DELETE_SUCCESS";

export const setUsers = (payload) => {
  action({ type: SET_USERS, payload });
};

export const setSelectedUser = (payload) => {
  action({ type: SET_SELECTED_USER, payload });
};

export const setOpenUserDialogForm = (payload) => {
  action({ type: SET_OPEN_USER_DIALOG_FORM, payload });
};

export const setUserDeleteSuccess = (payload) => {
  action({ type: SET_USER_DELETE_SUCCESS, payload });
};

// projects
export const SET_PROJECTS = "SET_PROJECTS";
export const SET_SELECTED_PROJECT = "SET_SELECTED_PROJECT";
export const SET_OPEN_PROJECT_DIALOG_FORM = "SET_OPEN_PROJECT_DIALOG_FORM";
export const SET_PROJECT_DELETE_SUCCESS = "SET_PROJECT_DELETE_SUCCESS";

export const setProjects = (payload) => {
  action({ type: SET_PROJECTS, payload });
};

export const setSelectedProject = (payload) => {
  action({ type: SET_SELECTED_PROJECT, payload });
};

export const setOpenProjectDialogForm = (payload) => {
  action({ type: SET_OPEN_PROJECT_DIALOG_FORM, payload });
};

export const setProjectDeleteSuccess = (payload) => {
  action({ type: SET_PROJECT_DELETE_SUCCESS, payload });
};

// pipelines
export const SET_PIPELINES = "SET_PIPELINES";
export const SET_SELECTED_PIPELINE = "SET_SELECTED_PIPELINE";
export const SET_OPEN_PIPELINE_DIALOG_FORM = "SET_OPEN_PIPELINE_DIALOG_FORM";
export const SET_PIPELINE_DELETE_SUCCESS = "SET_PIPELINE_DELETE_SUCCESS";
export const SET_PIPELINE_TRIGGER_REQUEST = "SET_PIPELINE_TRIGGER_REQUEST";

export const setPipelines = (payload) => {
  action({ type: SET_PIPELINES, payload });
};

export const setSelectedPipeline = (payload) => {
  action({ type: SET_SELECTED_PIPELINE, payload });
};

export const setOpenPipelineDialogForm = (payload) => {
  action({ type: SET_OPEN_PIPELINE_DIALOG_FORM, payload });
};

export const setPipelineDeleteSuccess = (payload) => {
  action({ type: SET_PIPELINE_DELETE_SUCCESS, payload });
};

export const setPipelineTriggerRequest = (payload) => {
  action({ type: SET_PIPELINE_TRIGGER_REQUEST, payload });
};

// roles
export const SET_ROLES = "SET_ROLES";
export const SET_OPEN_ROLES_DIALOG_FORM = "SET_OPEN_ROLES_DIALOG_FORM";
export const SET_SELECTED_ROLE = "SET_SELECTED_ROLE";

export const setRoles = (payload) => {
  action({ type: SET_ROLES, payload });
};

export const setSelectedRole = (payload) => {
  action({ type: SET_SELECTED_ROLE, payload });
};
export const setOpenRolesDialogForm = (payload) => {
  action({ type: SET_OPEN_ROLES_DIALOG_FORM, payload });
};
