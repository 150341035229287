import React, { useEffect } from "react";

import "./adminPanel.css";

// import BasicTabs from "../../Components/Tabs/Tabs";
// import UserManagement from "./UserManagementPage/UserManagementPage";
// import ProjectManagement from "./ProjectManagementPage/ProjectManagementPage";
// import JenkinsProjectManagement from "./JenkinsProjectManagementPage/JenkinsProjectManagementPage";
// import PipelineManagement from "./PipelineManagementPage/pipelineManagement";
import PermanentDrawerLeft from "../../Components/PermanentDrawerLeft/PermanentDrawerLeft";

import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import UserIcon from "./userIcon.svg";
import ProjectIcon from "./projectIcon.svg";
import PipeLineIcon from "./pipelineIcon.svg";

import { useAuth } from "../../Hooks/useAuth";

const tabs = [
  {
    text: "User Management",
    path: "/admin-panel/user-management/users",
    icon: <img src={UserIcon} alt="User Icon" style={{ width: "20px" }} />,
    subItems: [
      { text: "Users", path: "/admin-panel/user-management/users" },
      { text: "Roles", path: "/admin-panel/user-management/roles" },
    ],
  },
  {
    text: "Project Management",
    path: "/admin-panel/project-management",
    icon: <img src={ProjectIcon} alt="User Icon" style={{ width: "20px" }} />,
    subItems: [{ text: "Projects", path: "/admin-panel/project-management" }],
  },
  {
    text: "Pipeline Management",
    path: "/admin-panel/pipeline-management/pipelines",
    icon: <img src={PipeLineIcon} alt="User Icon" style={{ width: "20px" }} />,
    subItems: [
      { text: "Pipeline", path: "/admin-panel/pipeline-management/pipelines" },
      {
        text: "Trigger Request",
        path: "/admin-panel/pipeline-management/trigger-request",
      },
      // {
      //   text: "Pipeline History",
      //   path: "/admin-panel/pipeline-management",
      // },
    ],
  },
];

const AdminPanel = () => {
  const { pathname } = useLocation();
  const { checkLogin, loggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    checkLogin();
  }, []);

  useEffect(() => {
    if (!loggedIn) navigate("/signin");
  }, [loggedIn]);

  return (
    <div className="admin-panel-container">
      {/* <h2 className="admin-panel-heading">Admin Panel Page</h2> */}

      <PermanentDrawerLeft menuItems={tabs}>
        {pathname === "/admin-panel" ? (
          <Navigate to="/admin-panel/pipeline-management/pipelines" replace />
        ) : (
          <Outlet />
        )}
      </PermanentDrawerLeft>
    </div>
  );
};

export default AdminPanel;
