import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  setPipelines,
  setSelectedPipeline,
  setOpenPipelineDialogForm as setOpenDialog,
  setPipelineDeleteSuccess,
  setIsLoading,
  setProjects,
  setUsers,
} from "../../../State/actions";

import { fetchProjects } from "../ProjectManagementPage/ProjectManagementPage";
import { fetchUsers } from "../UserManagementPage/UserManagementPage";
import EditIcon from "../../../Components/Icons/EditIcon.svg";
import DeleteIcon from "../../../Components/Icons/DeleteIcon.svg";
// import SaveIcon from "@mui/icons-material/Save";
// import CancelIcon from "@mui/icons-material/Close";
import {
  GridActionsCellItem,
  // GridRowEditStopReasons,
} from "@mui/x-data-grid";

import "./pipelineManagement.css";

import { toast } from "react-toastify";

import DataTable from "../../../Components/DataGrid/DataGrid";
import AxiosWrapper from "../../../Api/http/AxiosWrapper";
const { REACT_APP_API_BASE_URL } = process.env;

import getPipelinesData from "../../../Api/request/pipelines";

const api = new AxiosWrapper(REACT_APP_API_BASE_URL);

const handleRowEdit = (id, row) => () => {
  console.log("handleRowEdit :" + id);
  setSelectedPipeline(row);
  setOpenDialog(true);
};

const handleRowDelete = (id) => async () => {
  console.log("handleRowDelete:" + id);
  await deletePipeline(id);
};

// eslint-disable-next-line
const handleFormSubmit =
  (selectedPipeline, pipelinesState, setPipelinesState) => () => {
    if (!selectedPipeline.id) {
      createPipeline(selectedPipeline, pipelinesState, setPipelinesState);
    } else {
      updatePipeline(selectedPipeline, pipelinesState, setPipelinesState);
    }
    setSelectedPipeline({
      name: "",
      environment: "",
      status: "",
      approverId: "",
      projectId: "",
      userId: "",
    });
  };

const columnsArr = [
  // { field: "id", headerName: "ID", width: 70 },
  { field: "name", headerName: "Name", width: 170 },
  { field: "projectName", headerName: "Project", width: 100 },
  { field: "approverNames", headerName: "Approver", width: 100 },
  { field: "environment", headerName: "Environment", width: 100 },

  {
    field: "status",
    headerName: "Status",
    width: 90,
  },
  {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    width: 200,
    cellClassName: "actions",
    getActions: ({ id, row }) => {
      return [
        <GridActionsCellItem
          icon={<img src={EditIcon} alt="Edit Icon" />}
          label="Edit"
          className="textPrimary"
          onClick={handleRowEdit(id, row)}
          color="inherit"
          key="edit"
        />,
        <GridActionsCellItem
          icon={<img src={DeleteIcon} alt="Edit Icon" />}
          label="Delete"
          onClick={handleRowDelete(id)}
          color="inherit"
          key="delete"
        />,
      ];
    },
  },
];

const dialogFormInputs = [
  {
    id: "name",
    label: "Name",
    type: "text",
    required: true,
  },
  {
    id: "environment",
    label: "Environment",
    type: "text",
    required: true,
  },

  {
    id: "jobId",
    label: "Jenkins Job Id",
    type: "text",
    required: true,
  },

  {
    id: "status",
    label: "Status",
    type: "select",
    selectedValue: "ACTIVE",
    options: [
      { value: "ACTIVE", label: "Active" },
      { value: "INACTIVE", label: "In active" },
    ],
  },
  {
    id: "projectId",
    label: "Project",
    type: "select",
    selectedValue: "",
    options: [],
  },
  {
    id: "approverId",
    label: "Approver",
    type: "select",
    isMultiple: true,
    selectedValue: "",
    options: [],
  },
];

// const paginationModel = { pcreatedDate: 0, pcreatedDateSize: 50 };

const fetchData = async (
  page,
  pageSize,
  setPipelinesState,
  setProjectsState,
  setUsersState,
  setPaginationModel
) => {
  try {
    setIsLoading(true);

    await fetchPipelines(page, pageSize, setPipelinesState, setPaginationModel);
    await fetchUsers(1, 1000, setUsersState);
    await fetchProjects(1, 1000, setProjectsState);

    setIsLoading(false);
  } catch (error) {
    console.error(error?.message);

    toast.error(error?.response?.date?.data?.message || error?.message);
    setIsLoading(false);
  }
};

export const fetchPipelines = async (
  page,
  pageSize,
  setPipelinesState,
  setPaginationModel
) => {
  try {
    setIsLoading(true);
    const pipelinesRequestData = getPipelinesData();
    const response = await api.get(
      `${pipelinesRequestData.url}?page=${page}&pageSize=${pageSize}`
    );

    if (!response?.data?.data) {
      throw new Error("Invalid response from server");
    }

    const { pipelines = [], pagination = {} } = response.data.data;

    setPipelinesState(pipelines);
    setPaginationModel((prev) => ({
      ...prev,
      ...pagination,
    }));
  } catch (error) {
    console.error(error?.message);
    toast.error(error?.response?.data?.message || error?.message);
  } finally {
    setIsLoading(false);
  }
};

const createPipeline = async (
  selectedPipeline,
  pipelinesState,
  setPipelinesState
) => {
  try {
    setIsLoading(true);
    const pipeliensRequestData = getPipelinesData();
    const response = await api.post(pipeliensRequestData.url, selectedPipeline);
    const pipeline = response?.data?.data?.pipeline;
    setPipelinesState([...pipelinesState, pipeline]);
    toast.success(response?.data?.message || "Pipeline Created successfully");
    setIsLoading(false);
  } catch (error) {
    console.error(error?.message);

    toast.error(error?.response?.date?.data?.message || error?.message);
    setIsLoading(false);
  }
};

const updatePipeline = async (
  selectedPipeline,
  pipelinesState,
  setPipelinesState
) => {
  try {
    setIsLoading(true);
    const pipeliensRequestData = getPipelinesData();
    const id = selectedPipeline?.id;
    const response = await api.put(
      `${pipeliensRequestData.url}/${id}`,
      selectedPipeline
    );
    const pipeline = response?.data?.data?.pipeline;
    const newState = pipelinesState?.map((pipelineState) => {
      if (pipelineState.id === selectedPipeline.id) return pipeline;
      else return pipelineState;
    });
    setPipelinesState(newState);
    toast.success(response?.data?.message || "Pipeline updated successfully");
    setIsLoading(false);
  } catch (error) {
    console.error(error?.message);

    toast.error(error?.response?.date?.data?.message || error?.message);
    setIsLoading(false);
  }
};

const deletePipeline = async (id) => {
  try {
    const isconfirmed = confirm(
      "Are you sure you want to delete the pipeline with id: " + id
    );
    if (!isconfirmed) return;
    setIsLoading(true);
    const pipelinesRequestData = getPipelinesData();
    await api.delete(`${pipelinesRequestData.url}/${id}`);

    setPipelineDeleteSuccess(id);
    toast.success("Pipeline deleted successfully");
    setIsLoading(false);
  } catch (error) {
    console.error(error?.message);

    toast.error(error?.response?.date?.data?.message || error?.message);
    setIsLoading(false);
  }
};

/* eslint-disable max-lines-per-function */
const PipelineManagement = () => {
  const pipelinesState = useSelector((state) => state?.pipeline?.pipelines);
  const projectsState = useSelector((state) => state?.project?.projects);
  const usersState = useSelector((state) => state?.user?.users);
  const [rows, setRows] = useState(pipelinesState);
  const [columns, setColumns] = useState(columnsArr);
  const [formInputs, setFormInputs] = useState(dialogFormInputs);
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 5,
    totalItems: 0,
  });
  const selectedPipeline = useSelector(
    (state) => state.pipeline.selectedPipeline
  );
  const selectedProjectId = useSelector(
    (state) => state.pipeline.selectedProjectId
  );
  const openDialog = useSelector((state) => state.pipeline.openDialog);

  useEffect(() => {
    fetchData(
      paginationModel.page,
      paginationModel.pageSize,
      setPipelines,
      setProjects,
      setUsers,
      setPaginationModel
    );
  }, [paginationModel.page, paginationModel.pageSize]);

  useEffect(() => {
    const projectOptions = projectsState
      ?.filter((project) => project?.status === "ACTIVE")
      ?.map((project) => {
        return { value: project?.id, label: project?.name };
      });

    const selectedProjectObject = projectsState.find(
      (project) => project.id === selectedProjectId
    );

    const userOptions = usersState
      ?.filter((user) =>
        selectedProjectObject?.approversList?.includes(user?.id)
      )
      .map((user) => {
        return { value: user?.id, label: user?.email };
      });

    const dialogFormInputsLength = dialogFormInputs.length;
    const newDialogFormInputs = [...dialogFormInputs];
    newDialogFormInputs[dialogFormInputsLength - 1].options = userOptions;
    newDialogFormInputs[dialogFormInputsLength - 2].options = projectOptions;
    setFormInputs(newDialogFormInputs);
  }, [projectsState, usersState, selectedProjectId]);

  useEffect(() => {
    setRows(pipelinesState);
  }, [pipelinesState, projectsState, usersState]);

  const LOCKED_FIELDS = ["name", "projectId"];
  const disabledDialogFormInputs = formInputs?.map((input) => ({
    ...input,
    disabled: LOCKED_FIELDS.includes(input?.id),
  }));

  const handlePaginationChange = (data) =>
    setPaginationModel({ page: data?.page + 1, pageSize: data?.pageSize });

  return (
    <div className="about-container">
      <DataTable
        rows={rows}
        columns={columns}
        setColumns={setColumns}
        paginationModel={paginationModel}
        pageSizeOptions={[5, 10, 15, 50, 100]}
        opendialog={openDialog}
        setOpenDialog={setOpenDialog}
        formInputs={
          selectedPipeline?.id ? disabledDialogFormInputs : dialogFormInputs
        }
        formState={selectedPipeline}
        setFormState={setSelectedPipeline}
        handleFormSubmit={handleFormSubmit(
          selectedPipeline,
          pipelinesState,
          setPipelines
        )}
        enableAddRecord={true}
        addDialogTitle={"Add Pipeline"}
        editDialogTitle={"Edit Pipeline"}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  );
};

export default PipelineManagement;
